import { Alert, Snackbar } from '@mui/material';
import * as React from 'react';






const SnakBar = ({setMassg,massg})=>{

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setMassg({
            ...massg,
            open : false
        });
    };
    return(
        
            <Snackbar open={massg.open} autoHideDuration={6000} onClose={handleClose}>
                <Alert  severity={massg.severity} sx={{ width: '100%' }} onClose={handleClose}>
                    {
                        massg.massg
                    }
                </Alert>
            </Snackbar>
       
    )
}

export default SnakBar