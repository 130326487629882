import { Button, Container, Divider, LinearProgress, Paper, TextField } from "@mui/material";
import React from "react";
import { Api } from "../../constant/api";
import SnakBar from "../../constant/massg";



export default function Changepass(){
    const [formData, setFormData] = React.useState({})
    const [err, setErr] = React.useState({})
    const [loading,setLoading] = React.useState(false)
    const [massg,setMassg] = React.useState({})

    const onChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }

    const validate = ()=>{
        let valid = true
        let err = {}

        if(!formData.old_pass){
            valid = false
            err['old_pass'] = true
        }
        if(!formData.new_pass){
            valid = false
            err['new_pass'] = true
        }
        if(!formData.con_pass){
            valid = false
            err['con_pass'] = true
        }

        if(formData.new_pass != formData.con_pass){
            err['new_pass'] = true
            valid = false
            err['con_pass'] = true
        }

        setErr(err)
        return valid
    }

    const onSubmit = ()=>{
        if(validate()){
            setLoading(true)
                    fetch(Api+'updatePass',{
                      method : 'POST',
                      body : JSON.stringify(formData)
                    })
                    .then(res=>res.json())
                    .then(res=>{
                    console.log(res)
                      setLoading(false)
                      if(res.code === 200){
                        setMassg({
                          open : true,
                          severity : 'success',
                          massg : res.massg
                        })
                      }else{
                        setMassg({
                          open : true,
                          severity : 'error',
                          massg : res.massg
                        })
                      }
                    })
                    .catch(err=>{
                      setLoading(false)
                      setMassg({
                        open : true,
                        severity : 'error',
                        massg : 'Faild to connect to the server'
                      })
                    })
        }
    }


    return(
        <Container maxWidth="sm">
            <Paper style={{padding: 10}} variant="outlined">
                <h3>Change Admin Password</h3>
                <Divider />
                {loading ? <LinearProgress /> : ''}
                <TextField 
                    variant="outlined"
                    label="Enter Old Password"
                    name="old_pass"
                    type='password'
                    style={{marginTop: 20}}
                    fullWidth
                    value={formData.old_pass}
                    onChange={onChange}
                    error={err.old_pass}
                   
                />
                <TextField 
                    variant="outlined"
                    label="Enter New Password"
                    name="new_pass"
                    type='password'
                    style={{marginTop: 20}}
                    fullWidth
                    value={formData.new_pass}
                    onChange={onChange}
                    error={err.new_pass}
                />
                <TextField 
                    variant="outlined"
                    label="Confirm New Password"
                    name="con_pass"
                    type='password'
                    style={{marginTop: 20}}
                    fullWidth
                    value={formData.con_pass}
                    onChange={onChange}
                    error={err.con_pass}
                />
                <Button variant="contained" onClick={onSubmit} style={{marginTop: 20}}>
                    Submit
                </Button>
            </Paper>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}