import { Button, TextField,Box, Container, Paper } from "@mui/material";
import React from "react";
import { Api } from "../../constant/api";
import FileResizer from 'react-image-file-resizer'
import SnakBar from "../../constant/massg";
import {useLocation} from "react-router-dom"


export default function StudentEdit(){
    const location = useLocation()

    const [formData, setFormData] = React.useState(location.state)
    const [err, setErr] = React.useState({})
    const [image, setImage] = React.useState(null)
    const [massg,setMassg] = React.useState({})

    const onChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }

    const handleImage = (e)=>{
        try {
            FileResizer.imageFileResizer(
            e.target.files[0],
            800,
            1000,
            "PNG",
            80,
            0,
            (uri) => {
             setImage(uri)
            },
            "file",
            200,
            200
            );
      
        
        } catch (err) {
            alert('Something went wrong, please select another image')
        }
    }


    const submit = ()=>{
      
            
            const data = new FormData
            Object.keys(formData).map((item,index)=>{
               data.append(item,formData[item])
            })
            data.append('image',image)
            data.append('image_name', formData.img)

            fetch(Api+'studentEdit',{
                method : 'POST',
                body : data
            })
            .then(res=>res.json())
            .then(res=>{
                setMassg({
                    open : true,
                    massg : res.massg,
                    severity : res.code === 200 ? 'success' : 'error'
                })
            })
            .catch(err=>{
                setMassg({
                    open : true,
                    massg :  "Faild to connect to the server",
                    severity : 'error'
                })
            })
        
    }

    

    return(
        <Container maxWidth="xs" component={Paper} variant="outlined">
            <Box style={{padding: 14}}>
                <TextField
                    variant="outlined"
                    label="Student Name"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="name"
                    value={formData.name}
                    onChange={onChange}
                    error={err.name}
                    required

                    
                />
                <TextField
                    variant="outlined"
                    label="Student father/mother name"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="f_name"
                    value={formData.f_name}
                    onChange={onChange}
                    error={err.f_name}
                    required
                />
                <TextField
                    variant="outlined"
                    helperText="DOB"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={onChange}
                    error={err.dob}
                    required
                    
                />
                <TextField
                    variant="outlined"
                    label="Registration/Certificate No"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="r_no"
                    value={formData.r_no}
                    onChange={onChange}
                    error={err.r_no}
                    required

                />
                <TextField
                    variant="outlined"
                    label="Course Name"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="c_name"
                    value={formData.c_name}
                    onChange={onChange}
                    error={err.c_name}
                    required

                />
                <TextField
                    variant="outlined"
                    label="Course Details"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="c_details"
                    value={formData.c_details}
                    onChange={onChange}
                    error={err.c_details}
                    required

                />
                <TextField
                    variant="outlined"
                    label="status"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="status"
                    value={formData.status}
                    onChange={onChange}
                    error={err.status}
                    required

                />
                <TextField
                    variant="outlined"
                    helperText="Student Photo"
                    fullWidth
                    style={{
                        margin: 5
                    }}

                    type="file"
                    name="image"
                    error={err.image}
                    onChange={handleImage}
                   
                />
                <TextField
                    variant="outlined"
                    label="Result"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="res"
                    value={formData.res}
                    onChange={onChange}
                    error={err.res}
                    required

                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={submit}
                >
                    Submit
                </Button>
            </Box>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}