import { Button, TextField,Box, Container, Paper, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, IconButton, Toolbar, Menu, MenuItem, Typography, Avatar, LinearProgress } from "@mui/material";
import {FiMoreVertical} from 'react-icons/fi'
import {BiSearchAlt2} from 'react-icons/bi'
import color from "../../../constant/colors";
import {AiTwotoneEdit} from 'react-icons/ai'
import {IoQrCode} from 'react-icons/io5'
import {CgArrowsExchangeAlt} from 'react-icons/cg'
import React from "react";
import {Link} from 'react-router-dom'
import CryptoJS from "crypto-js";
import { Api,ImageApi } from "../../../constant/api";
import SnakBar from "../../../constant/massg";


export default function Dashboard(){
    const [anchor,setAnchor] = React.useState(null)
    const open = Boolean(anchor)
    const [selected, setSelected] = React.useState({})
    const [data,setData] = React.useState([])
    const [anchorSubMenu, setsubMenuOpen] = React.useState(null)
    const [massg,setMassg] = React.useState({})
    const subMenuOpen = Boolean(anchorSubMenu)
    const [refresh, setRefresh] = React.useState(Math.random())
    const [loading,setLoading] = React.useState(false)
    const handleMenu = (e,val)=>{
        setAnchor(e)
        setSelected(val)
    }

    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'all_students')
        .then(res=>res.json())
        .then(res=>{
            setData(res)
            setLoading(false)
        })
        .catch(err=>{
           console.log(err)
           setLoading(false)
        })
        
    },[refresh])

    const handleSearch = (e)=>{
        setLoading(true)
        fetch(Api+'search_students',{
            method: 'POST',
            body : JSON.stringify({
                search : e.target.value
            })
        })
        .then(res=>res.json())
        .then(res=>{
            setData(res)
            setLoading(false)
            
        })
        .catch(err=>{
            setLoading(false)
           console.log(err)
        })
    }

    const handlesubMenu = (e,val)=>{
        setsubMenuOpen(e)
    }

    const handleStatus = (e,id)=>{
        setLoading(true)
        fetch(Api+'updateStatus',{
            method : 'POST',
            body : JSON.stringify({
                id : id,
                status : e
            })
        })
        .then(res=>res.json())
        .then(res=>{
            setMassg({
                open : true,
                massg : res.massg,
                severity : res.code === 200 ? 'success' : 'error'
            })
            setRefresh(Math.random())
            setsubMenuOpen(null)
            setAnchor(null)
            setLoading(true)
        })
        .catch(err=>{
            setLoading(false)
            setMassg({
                open : true,
                massg : "Faild to connect to the server",
                severity :  'error'
            })
        })
    }
    

    return(
        <Container maxWidth="lg" style={{marginTop:  50}} >
            <Paper
                 variant="outlined"
                 style={{
                   
                    marginBottom: 5
                 }}
            >
                <Toolbar>
                <TextField
                    variant="outlined"
                    label="Search for students"
                    size="small"
                    onChange={handleSearch}
                />
                <IconButton
                    style={{
                        backgroundColor : color.secondary,
                        color: color.primary,
                        marginLeft: 10
                    }}
                    component={Paper}
                >
                    <BiSearchAlt2 />
                </IconButton>
                </Toolbar>
            </Paper>
            <TableContainer component={Paper} variant="outlined">
                {loading ? <LinearProgress /> : ''}
                <Table>
                    <TableHead>
                        <TableCell></TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Registration No</TableCell>
                        <TableCell>DOB</TableCell>
                        <TableCell>F/M name</TableCell>
                        <TableCell>Course Name</TableCell>
                        <TableCell>Course Details</TableCell>
                        <TableCell>Result</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>More</TableCell>
                    </TableHead>
                    <TableBody>
                    {
                        data.map((item,index)=>
                            <TableRow key={index}>
                                <TableCell>
                                    <Avatar>
                                        <img src={ImageApi+item.img} style={{width: '100%', height: '100%'}} />
                                    </Avatar>
                                </TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.r_no}</TableCell>
                                <TableCell>{item.dob}</TableCell>
                                <TableCell>{item.f_name}</TableCell>
                                <TableCell>{item.c_name}</TableCell>
                                <TableCell>{item.c_details}</TableCell>
                                <TableCell>{item.res}</TableCell>
                                <TableCell>{item.status}</TableCell>
                                <TableCell>
                                    <IconButton size="small" onClick={({currentTarget : value})=>handleMenu(value,item)}>
                                        <FiMoreVertical />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    }
                    </TableBody>
                </Table>
            </TableContainer>

            <Menu
                anchorEl={anchor}
                open={open}
                onClose={()=>setAnchor(null)}
            >
                <MenuItem>
                    <Link to={'/StudentEdit'} state={selected}  style={{display : 'flex',textDecoration : 'none',color: '#000',alignItems: 'center'}}>
                        <AiTwotoneEdit />
                        <Typography style={{marginLeft: 5}}>Edit</Typography>
                    </Link>
                </MenuItem>
                {/* CryptoJS.AES.encrypt(JSON.stringify(selected.id),'test').toString().replace(/\+/g,'p1L2u3S').replace(/\//g,'s1L2a3S4h').replace(/=/g,'e1Q2u3A4l') */}
                
                <Link to={'/QrGenerator/' +encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(selected.id),'test').toString())}  style={{display : 'flex',textDecoration : 'none',color: '#000',alignItems: 'center'}} >
                <MenuItem>
                    <IoQrCode />
                    <Typography style={{marginLeft: 5}}> Download Qr Code</Typography>
                </MenuItem>
                </Link>
                <MenuItem onClick={({currentTarget : value})=>handlesubMenu(value,selected)}>
                    <CgArrowsExchangeAlt />
                    <Typography style={{marginLeft: 5}}> Change Status</Typography>
                    
                </MenuItem>
            </Menu>


            <Menu
                anchorEl={anchorSubMenu}
                open={subMenuOpen}
                onClose={()=>setsubMenuOpen(null)}
            >

                <MenuItem onClick={()=>handleStatus('Pending',selected.id)}>Pending</MenuItem>
                <MenuItem onClick={()=>handleStatus('Approved',selected.id)}>Approved</MenuItem>
                <MenuItem onClick={()=>handleStatus('Rejected',selected.id)}>Rejected</MenuItem>

            </Menu>

        <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}