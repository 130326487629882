import React from "react"



export default function Logout(){
    
    React.useEffect(()=>{
        sessionStorage.removeItem('token')
        setTimeout(()=>{
            window.location.replace('/')
        },1000)
    },[])
    return(
        <h1>Please wait</h1>
    )
}