import { Button, TextField,Box, Container, Paper, LinearProgress } from "@mui/material";
import React from "react";
import { Api } from "../../../constant/api";
import FileResizer from 'react-image-file-resizer'
import SnakBar from "../../../constant/massg";
import { useNavigate } from "react-router-dom";



export default function StudentRegister(){
    const navigate = useNavigate()

    const [formData, setFormData] = React.useState({})
    const [err, setErr] = React.useState({})
    const [image, setImage] = React.useState(null)
    const [massg,setMassg] = React.useState({})
    const [loading, setLoading] = React.useState(false)

    const onChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }

    const handleImage = (e)=>{
        try {
            FileResizer.imageFileResizer(
            e.target.files[0],
            800,
            1000,
            "PNG",
            80,
            0,
            (uri) => {
             setImage(uri)
            },
            "file",
            200,
            200
            );
      
        
        } catch (err) {
            alert('Something went wrong, please select another image')
        }
    }


    const submit = ()=>{
        if(validate()){
            setLoading(true)
            const data = new FormData
            Object.keys(formData).map((item,index)=>{
               data.append(item,formData[item])
            })
            data.append('image',image)

            
            fetch(Api+'studentEntry',{
                method : 'POST',
                body : data
            })
            .then(res=>res.json())
            .then(res=>{
                setLoading(false)
                setMassg({
                    open : true,
                    massg : res.massg,
                    severity : res.code === 200 ? 'success' : 'error'
                })
                setTimeout(()=>{
                    navigate('/')
                },1500)
            })
            .catch(err=>{
                setMassg({
                    open : true,
                    massg :  "Faild to connect to the server",
                    severity : 'error'
                })
            })
        }else{
            setLoading(false)
            setMassg({
                open : true,
                massg :  "Please fill all the required filled",
                severity : 'error'
            })
        }
    }

    const validate = ()=>{
        let err = {}
        let valid = true
        
        if(!formData.name){
            valid = false
            err['name'] = true
        }
        if(!formData.f_name){
            valid = false
            err['f_name'] = true
        }
        if(!formData.dob){
            valid = false
            err['dob'] = true
        }
        if(!formData.r_no){
            valid = false
            err['r_no'] = true
        }
        if(!formData.course_name){
            valid = false
            err['course_name'] = true
        }
        if(!formData.course_det){
            valid = false
            err['course_det'] = true
        }
        if(image === null){
            valid = false
            err['image'] = true
        }
        if(!formData.res){
            valid = "false"
            err['res'] = true
        }


        setErr(err)

        return valid

    }

    return(
        <Container maxWidth="xs" component={Paper} variant="outlined">
            {loading ? <LinearProgress /> : ''}
            <Box style={{padding: 14}}>
                <TextField
                    variant="outlined"
                    label="Student Name"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="name"
                    value={formData.name}
                    onChange={onChange}
                    error={err.name}
                    required

                    
                />
                <TextField
                    variant="outlined"
                    label="Student father/mother name"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="f_name"
                    value={formData.f_name}
                    onChange={onChange}
                    error={err.f_name}
                    required
                />
                <TextField
                    variant="outlined"
                    helperText="DOB"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={onChange}
                    error={err.dob}
                    required
                    
                />
                <TextField
                    variant="outlined"
                    label="Registration/Certificate No"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="r_no"
                    value={formData.r_no}
                    onChange={onChange}
                    error={err.r_no}
                    required

                />
                <TextField
                    variant="outlined"
                    label="Course Name"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="course_name"
                    value={formData.course_name}
                    onChange={onChange}
                    error={err.course_name}
                    required

                />
                <TextField
                    variant="outlined"
                    label="Course Details"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="course_det"
                    value={formData.course_det}
                    onChange={onChange}
                    error={err.course_det}
                    required

                />
                <TextField
                    variant="outlined"
                    helperText="Student Photo"
                    fullWidth
                    style={{
                        margin: 5
                    }}

                    type="file"
                    name="image"
                    error={err.image}
                    onChange={handleImage}
                   
                />
                <TextField
                    variant="outlined"
                    label="Result"
                    fullWidth
                    style={{
                        margin: 5
                    }}
                    name="res"
                    value={formData.res}
                    onChange={onChange}
                    error={err.res}
                    required

                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={submit}
                    disabled={loading}
                >
                    Submit
                </Button>
            </Box>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}