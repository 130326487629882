import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import color from '../../constant/colors';
import AppInfo from '../../appInfo.json'
import { useMediaQuery } from '@mui/material';
import NavList from './nav_list';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import StudentRegister from './masters/student_registration';
import QrDownloader from '../others/qr';
import Logout from '../auth/logout';
import StudentEdit from '../others/edit';
import Changepass from '../auth/change_pass';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open,isMobile }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: isMobile ? `-${drawerWidth}px` : 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',

}));

export default function Home() {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = React.useState(matchUpMd ? true : false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="fixed" 
        open={open}
        style={{
            backgroundColor: color.white,
            color: color.black

        }}
        elevation={0}
      >
        <Toolbar>
          
          
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 0,
                 ...(open && { display: 'none' }),
                 backgroundColor: color.secondary,
                 color: color.primary,
                 marginLeft: 5
                 }}
          >
            <MenuIcon />
          </IconButton> */}
          <IconButton 
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            sx={{ mr: 0,
                backgroundColor: color.secondary,
                color: color.primary,
                marginLeft: 1
                }}
        >
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <Typography style={{color: color.black, ...(open && {display: 'none'})}} variant="h6" noWrap component="div">
            {AppInfo.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        anchor="left"
        open={open}
        PaperProps={{style: {border: 'none'}}}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        onClose={()=>setOpen(false)}
      >
        <DrawerHeader style={{height: '4vh',margin:0}}>
          <Typography style={{color: color.black,marginRight: 10,fontWeight: 600,marginLeft: 15}} variant="h6" noWrap component="div">
            {AppInfo.name}
          </Typography>
          
        </DrawerHeader>
        <NavList />
        
      </Drawer>
      <Main 
        open={open}
        style={{
            backgroundColor : color.white,
            height: '94vh',
            marginTop: 50,
            maxWidth : '100%',
            padding: matchUpMd ? '' : 0
            
        }}
        isMobile={matchUpMd}
      >
        
        <Box
            style={{
                backgroundColor : color.background,
                height: '92vh',
                borderRadius: 15,
                padding: matchUpMd ? 20 : '',
                overflow: 'auto'

            }}
        >
       
            <Routes>
                <Route path='/' element={<Dashboard />} />
                <Route path='/StudentRegister' element={<StudentRegister />} />
                <Route path='/QrGenerator/:id' element={<QrDownloader />} />
                <Route path='/SignOut' element={<Logout />} />
                <Route path='/StudentEdit' element={<StudentEdit />} />
                <Route path='/Changepass' element={<Changepass />} />
            </Routes>
       
        </Box>
      </Main>
      </Box>
    </Box>
  );
}
















// import { AppBar, Box, Drawer, IconButton, Typography } from "@mui/material";
// import {HiOutlineMenuAlt1} from 'react-icons/hi'
// import color from "../../constant/colors";
// import React from "react";

// export default function Home(){
//     const [drawerOpen, setDrawerOpen] = React.useState(false)
//     const handleDrawer = ()=>{
//         setDrawerOpen(!drawerOpen)
//     }

//     return (
//         <Box sx={{ display: 'flex' }}>
//            <AppBar
//                 style={{
                    
//                     padding: 15,
//                     display: 'flex',
//                     flexDirection : 'row',
//                     alignItems: 'center',
//                     backgroundColor: '#fff',
//                     color: '#000'
//                 }}
//                 elevation={0}
//                 position="fixed"
//                 open={drawerOpen}
//            >
//             <Typography component="h1" variant="h5">
//                 DITAMC 
//             </Typography>
//             <IconButton
//                 style={{
//                     marginLeft: 20,
//                     backgroundColor: color.secondary,
//                     color: color.primary
//                 }}
//                 onClick={handleDrawer}
//             >
//                 <HiOutlineMenuAlt1 />
//             </IconButton>
//             </AppBar> 
//             <Drawer
//                 open={drawerOpen}
//                 sx={{
//                     width: 240,
//                     flexShrink: 0,
//                     '& .MuiDrawer-paper': {
//                       width: 240,
//                       boxSizing: 'border-box',
//                     },
//                   }}
//                   variant="persistent"
//                   anchor="left"
//                 onClose={handleDrawer}
//             >
//                 this is Drawer
//             </Drawer>
//             <Box style={{marginTop: 100}}>
//             <Typography paragraph>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//             tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
//             enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
//             imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
//             Convallis convallis tellus id interdum velit laoreet id donec ultrices.
//             Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
//             adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
//             nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
//             leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
//             feugiat vivamus at augue. At augue eget arcu dictum varius duis at
//             consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
//             sapien faucibus et molestie ac.
//             </Typography>
//             <Typography paragraph>
//             Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
//             eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
//             neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
//             tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
//             sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
//             tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
//             gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
//             et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
//             tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
//             eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
//             posuere sollicitudin aliquam ultrices sagittis orci a.
//             </Typography>
//             </Box>
//         </Box>
//     )
// }