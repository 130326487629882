import {AiOutlineDashboard,AiOutlineLogout} from 'react-icons/ai'
import { Divider, List, ListItemButton, ListSubheader, Toolbar, Typography } from '@mui/material'
import color from '../../constant/colors'
import React from 'react'
import {GiArchiveRegister} from 'react-icons/gi'
import {TbInfoSquare} from 'react-icons/tb'
import {useNavigate} from 'react-router-dom'
import { Lock } from '@mui/icons-material'
const list = [
    {
       name : 'Dashboard',
       sub_list : [
        {
            name: 'Dashboard',
            link : '/',
            icon : <AiOutlineDashboard size={24} />,
        }
       ]
    },
    {
       name : 'Main Masters',
       sub_list : [
        {
            name: 'Student Register',
            link : '/StudentRegister',
            icon : <GiArchiveRegister size={24} />,
        },
        {
            name: 'Student Details',
            link : '/',
            icon : <TbInfoSquare size={24} />,
        }
       ]
    },
    {
        name : 'Authentication',
        sub_list : [
         {
             name: 'Sign Out',
             link : '/SignOut',
             icon : <AiOutlineLogout size={24} />,
         },
         {
             name: 'Change Password',
             link : '/Changepass',
             icon : <Lock size={24} />,
         },
        ]
     },
]

export default function NavList(){
    const navigate = useNavigate()
    const [selected, setSelected] = React.useState({main : 0, sub : 0})

    const handleRoute = (item,main,sub)=>{
        navigate(item.link)
        setSelected({
            main : main,
            sub: sub
        })
    }

    return(
        <>
        {
            list.map((item,index)=>
                <List
                    key={index}
                    component="nav"
                    subheader={
                        <ListSubheader component={'div'} style={{fontWeight: 600,padding: 0,margin: 0}} id="nested-list-subheader">
                            {item.name}
                        </ListSubheader>
                    }
                    style={{
                        padding: 10,
                        paddingLeft: 20
                    }}
                >
                {
                    item.sub_list != null ? (
                    <List>
                    {
                        item.sub_list.map((sub_item,sub_index)=>
                            <ListItemButton onClick={()=>handleRoute(sub_item,index,sub_index)} key={sub_index} style={{marginLeft: 15,borderRadius: 10, backgroundColor: index === selected.main && sub_index === selected.sub ? color.secondary : '',color: index === selected.main && sub_index === selected.sub ? color.primary : '',marginBottom: 15  }} >
                                {sub_item.icon}
                                <Typography style={{marginLeft: 10}}>
                                    {sub_item.name}
                                </Typography>
                            </ListItemButton>
                        )
                    }
                    </List>
                    ) : ''
                }
                {
                    index + 1 === list.length ? '' : <Divider />
                }
                </List>
            )
        }
        </>
    )
}