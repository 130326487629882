import { ArrowBack } from "@mui/icons-material";
import { Button, Container, IconButton, Paper, Typography } from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
 import {useNavigate,useParams} from 'react-router-dom'




export default function QrDownloader(){
    const navigate = useNavigate()
    const param = useParams()
    const encryptedId = encodeURIComponent(param.id)
    console.log(encryptedId)
    const downloadQR = () => {
        const canvas = document.getElementById("123456");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "123456.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

    return(
        <Container maxWidth="md">
        <Paper variant="outlined" style={{marginBottom: 10,display : 'flex',padding:  5, alignItems : 'center'}}>
            <IconButton onClick={()=>navigate('/')}>
                <ArrowBack />
            </IconButton>
            <Typography variant="h6" component={'strong'}>Download QR code</Typography>
        </Paper>
        <div style={{display : 'flex', justifyContent: 'center',alignItems: 'center',flexDirection: 'column'}}>
        <QRCodeCanvas
            id="123456"
            value={"https://verification.ditamc.in/"+encryptedId}
            size={290}
            level={"H"}
            includeMargin={true}
        />
        <Button 
            onClick={downloadQR}
            variant="contained"
            style={{margin: 10}}
            color="success"
        > 
            Download QR 
        </Button>
        </div>
        </Container>
    )
}