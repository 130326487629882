
import './App.css';
import SignIn from './components/auth/login';
import Home from './components/Home';
import {BrowserRouter} from 'react-router-dom'
function App() {
  const authState = sessionStorage.getItem('token')
  return (
    <div className="App">
      {/* <SignIn /> */}
      <BrowserRouter>
      {
        !authState ? <SignIn /> : <Home />
      }
      </BrowserRouter>
    </div>
  );
}

export default App;
